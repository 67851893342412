import Api from '../../api/index'
import { endpoints } from '../../api/endPoints'
import { API_SUCCESS_CODE } from '../../api/constant'
import { ActionTypes } from '../../constants'
import axios from 'axios'
import { RegisterYourInterest } from '../../constants/model'
import { ReducerModel } from '../../reducers/reducerModel'

export const registerInterestUser = (
  payload: any,
  success: Function,
  fail: Function
) => {
  return (dispatch: Function, getState: Function) => {
    const {home : {appConfig : {joinWaitListkey}}}:ReducerModel = getState()
    Api.postApiCall(
      joinWaitListkey
        ? endpoints.auth.join_waitlist
        : endpoints.auth.register_interest,
      payload,
      (response: any) => {
        console.log({ response })
        const { responseCode, data, error, message } = response
        if (data && !error && responseCode == API_SUCCESS_CODE.success) {
          console.log(response)
          success({
            ...data,
          })
          // dispatch(loaderHandler(false))

          return
        }
        if (error && !data) {
          fail({ message })
          // dispatch(loaderHandler(false))

          return
        }
        success()
      },
      (error: any) => {
        fail(error)
        //   dispatch(loaderHandler(false))
      }
    )
  }
}

export const postGeoLocaiton = (
  payload: any,
  success: Function,
  fail: Function
) => {
  return (dispatch: Function, getState: Function) => {
    //   dispatch(loaderHandler(true))

    Api.postApiCall(
      endpoints.orbitGeo.location,
      payload,
      (response: any) => {
        const { responseCode, data, error, message } = response
        if (data && !error && responseCode == API_SUCCESS_CODE.success) {
          console.log(response)
          success({
            ...data,
          })
          // dispatch(loaderHandler(false))

          return
        }
        if (error && !data) {
          fail({ message })
          // dispatch(loaderHandler(false))

          return
        }
        success()
      },
      (error: any) => {
        fail(error)
        //   dispatch(loaderHandler(false))
      }
    )
  }
}

export const resetRYI = () => {
  return (dispatch: Function, getState: Function) => {
    dispatch({
      type: ActionTypes.RESET_RYI_USER_DETAILS,
      payload: new RegisterYourInterest(),
    })
  }
}
