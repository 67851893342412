import * as React from 'react';
import './cautionNoticeModal.scss'
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import Icons from '../../utils/icons';

export default function CautionNoticePopup({ open, handleClose }: any) {
    const history = useHistory()

    return (
        <div>
            <Dialog
                maxWidth={"md"}
                aria-labelledby="customized-dialog-title"
                open={open}
                className='modaldialog'
                fullWidth
            >
                <DialogContent className='modalcontent' dividers>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        className='close-dialog-icon'
                    >
                        <CloseIcon />
                    </IconButton>
                    <Grid container spacing={2} style={{padding: '1rem'}}>
                        <Grid item lg={12} md={12} style={{width: '100%'}}>
                            <img src={Icons.CautionPopupIcon} />
                        </Grid>
                        <Grid item lg={12} md={12}>
                            <h5 className='monument mb-20'>Caution Notice</h5>
                        </Grid>    
                        <Grid item lg={12}md={12}>
                            <p className='txt-p small'>At Matter, your security is our priority. Beware of fraudulent calls and emails claiming to be from Matter. We never make unsolicited calls or request payments/personal info via email. Stay cautious and only trust official communication channels listed on our website. Report suspicious calls/emails to our customer support team by writing to us at <a target="_blank" className='white bold' href='mailto:customer.care@matter.in'>customer.care@matter.in</a> or call us at <a target="_blank" className='white bold' href="tel:18002035630">1800-203-5630</a>. </p>
                        </Grid>    
                        <Grid item lg={12}md={12}>
                            <a className='mt-20 click-here-text' onClick={() => {
                                handleClose()
                                window.scrollTo(0,0)
                                !history.location.pathname.includes('caution-notice') && history.push('/caution-notice')
                            }}><p className='small'><u>Click here to read more</u></p></a>.
                            {/* <p className='txt-p'>Click here to read more.</p> */}
                        </Grid>   
                        <Grid item lg={12} md={12}>
                            <p className='txt-p txt-disclaimer small'>Disclaimer: Matter is not liable for losses due to fraudulent activities. Your safety matters to us!  </p>
                        </Grid>          
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}
