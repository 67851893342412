export const endpoints = {
  auth: {
    userDetails: 'matter-auth/v1/auth/user-details',
    postOtp: 'matter-auth/v1/auth/sms',
    verify: 'matter-auth/v1/auth/verify',
    updateUserData: 'matter-auth/v1/auth/user',
    otpValidate: 'matter-auth/v1/auth/validate',
    registerUser: 'matter-auth/v1/auth/register',
    deleteUser: 'matter-auth/v1/auth/delete-user',
    logout: 'matter-auth/v1/auth/logout',
    register_interest: 'matter-auth/v1/auth/register-interest',
    geo_locaiton: 'orbit-geo/v1/location',
    join_waitlist: "matter-auth/v1/auth/join-waitlist"
  },
  partner: {
    getChannelPartner: 'orbit-partner/v1/channel-partner',
    postChannelPartner: 'orbit-partner/v1/channel-partner',
    postChannelPartnerAnswers:
      'orbit-partner/v1/channel-partner/know-your-partner',
    knowChannelPartnerAnswers:
      'orbit-partner/v1/channel-partner/know-your-partner',
  },
  product: {
    cancel: 'orbit-product/v1/product/cancel',
    list: 'orbit-product/v1/product/list-order',
    modify: 'orbit-product/v1/product/modify-order',
    promoCode: 'orbit-product/v1/product/promocode',
    createPreBooking: 'orbit-product/v1/product/pre-book',
    priceDetails: 'orbit-product/v1/product/price-details',
    paymentStatus: 'orbit-product/v1/product/payment-status',
    locationListings: 'orbit-product/v1/product/location-listing',
    validatePrebook: 'orbit-product/v1/product/validate-prebook',
    agentList: 'orbit-product/v1/product/offline/executives',
    offlinePrebook: 'orbit-product/v1/product/offline/pre-book',
    cancelReason: 'orbit-product/v1/product/cancel-reason',
    requestAssistance: 'orbit-product/v1/product/request-assistance',
    getChannelPartner: 'orbit-product/v1/product/channel-partner',
    postChannelPartner: 'orbit-product/v1/product/channel-partner',
    postChannelPartnerAnswers: 'orbit-product/v1/product/know-your-partner',
  },
  orbitGeo: {
    location: 'orbit-geo/v1/location',
    dealerLocator: 'orbit-geo/v1/dealer-location',
  },
  dealerLocator: {
    getDealerList: 'orbit-dealer/v1/dealer/dealer-location',
  },
  strapi_dev: {
    topBanner: 'api/topbannermessage',
    contactUs: 'api/contact-us',
    masterFAQCategory: 'api/master-category-faqs?populate=*',
    childCategoryFAQList: 'api/child-category-faqs',
    masterChildFAQ: 'api/child-category-faqs',
    faqList: 'api/faq-maters',
    partnerWithUs: 'api/partner-with-us',
    getChannelPartnerQuestions: 'api/channel-partners',
    getInnovationTechStack: 'api/innovation-hub-tech-stack-devs',
    getWhoWeAreReward: 'api/who-we-are-reward-devs',
    getWhoWeAreEvent: 'api/who-we-are-event-devs',
    getWHoWeAre: 'api/who-we-are-devs?populate=*',
    whoWeAreLeaders: 'api/who-we-are-leader-list-devs?populate=*',
    getFullBlogs(id: number) {
      return 'api/blogs-devs/' + `${id}` + '?populate=*'
    },
    joinWaitlist: 'api/t-and-c-join-waitlist',
    getCategoryBlogs:
      'api/blogs-category-devs?populate[blogs][populate][0]=matterBlogImageWeb,matterBlogImageApp',
    homePage: 'api/home-page',
  },
  strapi: {
    topBanner: 'api/topbannermessage',
    contactUs: 'api/contact-us',
    masterFAQCategory: 'api/master-category-faqs?populate=*',
    childCategoryFAQList: 'api/child-category-faqs',
    masterChildFAQ: 'api/child-category-faqs',
    faqList: 'api/faq-maters',
    partnerWithUs: 'api/partner-with-us',
    getChannelPartnerQuestions: 'api/channel-partners',
    getInnovationTechStack: 'api/innovation-hub-tech-stacks',
    getWhoWeAreReward: 'api/who-we-are-rewards',
    getWhoWeAreEvent: 'api/who-we-are-events',
    getWHoWeAre: 'api/who-we-ares?populate=*',
    whoWeAreLeaders: 'api/who-we-are-leader-lists?populate=*',
    getFullBlogs(id: number) {
      return 'api/blogs/' + `${id}` + '?populate=*'
    },
    joinWaitlist: 'api/t-and-c-join-waitlist',
    getCategoryBlogs:
      'api/blogs-category?populate[blogs][populate][0]=matterBlogImageWeb,matterBlogImageApp',
    homePage: 'api/home-page',
  },
  orbitConfig: {
    config: 'orbit-config/v1/config',
  },
}
