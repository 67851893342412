import { Route } from 'react-router-dom'
import HomeBlogs from './home';
import CategoryBlogs from './categoryBlogs';
import FullBlog from './full-blog';

const Blogs = () => {
  return (
    <>           
      <Route
        path={'/blogs'}
        exact={true}
        render={() => <HomeBlogs />}
      />

      <Route
        path={'/blogs/category-blogs/:id'}
        exact={true}
        render={() => <CategoryBlogs />}
      />

      <Route
        path={'/blogs/:id'}
        exact={true}
        render={() => <FullBlog />}
      />      
       <Route
        path={'/blogs/category-blogs/blogs/:id'}
        exact={true}
        render={() => <FullBlog />}
      />                
    </>
  )
}

export default Blogs
