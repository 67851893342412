const Icons = {
  HamburgerIconBlack: '/public/assets/icons/hamburger-icon-black.svg',
  CloseWhiteEdgeIcon: '/public/assets/icons/close-white-edge.svg',
  locationPinPrimaryIcon: '/public/assets/icons/location-pin-primary.svg',
  BackChevronIcon: '/public/assets/icons/back-chevron.svg',
  NextChevronIcon: '/public/assets/icons/next-caret-icon.svg',
  RightChevronIconBlack: '/public/assets/icons/ic_arrow_right_black.svg',
  CloseBlackIcon: '/public/assets/icons/close-black.svg',
  CloseWhiteIcon: '/public/assets/icons/close-white.svg',
  Chat: '/public/assets/icons/chat-icon.svg',
  PrebookIcon: '/public/assets/images/prebook-icon.png',
  AboutUsIcon: '/public/assets/icons/about-us-icon.svg',
  PartnerWithUsIcon: '/public/assets/icons/partner-with-us-icon.svg',
  SupportIcon: '/public/assets/icons/support-icon.svg',
  MessageIcon: '/public/assets/icons/message-icon.svg',
  Interested: '/public/assets/icons/interested-icon.svg',
  DownCaretWhiteIcon: '/public/assets/icons/down-caret-white-icon.svg',
  DownCaretBlueIcon: '/public/assets/icons/down-caret-blue-icon.svg',
  BatteryEx: '/public/assets/icons/battery-ex-icon.svg',
  Battery: '/public/assets/icons/battery-icon.svg',
  BatteryBack: '/public/assets/icons/battery-back-icon.svg',
  Resale: '/public/assets/icons/resale-icon.svg',
  Rsa: '/public/assets/icons/rsa-icon.svg',
  Warranty: '/public/assets/icons/warranty-icon.svg',
  Compare: '/public/assets/icons/compare-icon.svg',
  CompareIcon: '/public/assets/icons/compare-icon.svg',
  Check: '/public/assets/icons/check.svg',
  CheckGreen: '/public/assets/icons/check-green.svg',
  CheckCancel: '/public/assets/icons/check-cancel.svg',
  CheckCancelRed: '/public/assets/icons/check-cancel-red.svg',
  MatterMini: '/public/assets/icons/matter-icon.svg',
  Invalid: '/public/assets/icons/invalid.svg',
  Mail_light: '/public/assets/icons/mail-light.svg',
  Call_light: '/public/assets/icons/call-light.svg',
  Mini_Logo_Black: '/public/assets/icons/mini-logo-black.svg',
  MusicOff: '/public/assets/icons/music-off.svg',
  MusicOn: '/public/assets/icons/music-on.svg',
  OtoLogo: '/public/assets/icons/oto-logo.svg',
  FlipkartLogo: '/public/assets/icons/flipkart-logo.svg',

  BigRedInfoIcon: '/public/assets/icons/big-red-info-icon.svg',
  BigGreenCheckIcon: '/public/assets/icons/big-green-check-icon.svg',
  QuestionWhiteIcon: '/public/assets/icons/question-white-icon.svg',

  CheckboxIcon: '/public/assets/icons/checkbox.svg',
  CheckboxActiveIcon: '/public/assets/icons/checkbox-active.svg',

  SearchGrayIcon: '/public/assets/icons/search-gray-icon.svg',
  CautionBannerIcon: '/public/assets/icons/caution-banner-icon.svg',
  CautionPopupIcon: '/public/assets/icons/caution-popup-icon.svg',

  TechEnergyIcon: '/public/assets/icons/tech-energy-icon.svg',
  TechLinkIcon: '/public/assets/icons/tech-link-icon.svg',
  TechEntelIcon: '/public/assets/icons/tech-entel-icon.svg',
  TechDriveIcon: '/public/assets/icons/tech-drive-icon.svg',
  TechXIcon: '/public/assets/icons/tech-x-icon.svg',

  XIcon: '/public/assets/icons/social-icons/x-icon.svg',
  InstaIcon: '/public/assets/icons/social-icons/insta-icon.svg',
  YoutubeIcon: '/public/assets/icons/social-icons/youtube-icon.svg',
  LinkedinIcon: '/public/assets/icons/social-icons/linkedin-icon.svg',
  FacebookIcon: '/public/assets/icons/social-icons/facebook-icon.svg',
  HamMenuIcon: '/public/assets/icons/ic-ham-menu@3x.svg',
  HamMenuCloseIcon: '/public/assets/icons/ic-close-ham.svg',
  ArrowIconUp: '/public/assets/icons/ic-up-arrow@2x.svg',
  ArrowIconDown: '/public/assets/icons/ic-down-arrow@2x.svg',

  RightChevronIconBlue: '/public/assets/icons/ic_arrow_right_blue.svg',
  DownCaretBlackIcon: '/public/assets/icons/down-caret-black-icon.svg',
  ShareIcon: '/public/assets/icons/share-icon.svg',
  
  CallIcon: '/public/assets/icons/call-icon.svg',
  CallIconMobile: '/public/assets/icons/call-icon-mobile.svg',
  WhatsAppIcon: '/public/assets/icons/whatsapp.svg',

  GetDirectionIcon: '/public/assets/icons/get-direction-icon.svg',
  GooglePoint :  '/public/assets/icons/Exclusion 7.svg',
  CurrentLocationPoint : '/public/assets/icons/currentLocation.svg',
  // hightligtedMarker : '/public/assets/icons/highlightMarker.svg',

  TargetIcon: '/public/assets/icons/target-icon.svg',
  CalenderIcon: '/public/assets/icons/calender-icon.svg',
  CalenderIconGrey: '/public/assets/icons/calender-icon-grey.svg',

  TimeSpeedIcon: '/public/assets/icons/time-speed.svg',
  TopSpeedIcon: '/public/assets/icons/top-speed.svg',
  PipeIcon: '/public/assets/icons/pipe.svg',
  ChargingTimeIcon: '/public/assets/icons/charging-time.svg',
  FastChargingTimeIcon: '/public/assets/icons/fast-charging-time.svg',
  UpArrow: './public/assets/icons/arrow_insert.svg',

  AreaPlusBikeIcon: './public/assets/icons/AERA_5000_plus_black.svg',
  AreaBikeIcon: './public/assets/icons/AERA_5000_black.svg',
  
  hightligtedMarker : '/public/assets/icons/highlightMarker.svg',
  highlightMarkerLite : '/public/assets/icons/highlightMarkerLite.svg'
}
export default Icons
