import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import Grid from '@mui/material/Grid'
import Icons from '../../../utils/icons'
import '../blogs.scss'
import { useSelector } from 'react-redux'
import { ReducerModel } from '../../../reducers/reducerModel'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { InputAdornment, Select, useMediaQuery, MenuItem } from '@mui/material'
import moment from 'moment'
import { BlogsCategory } from '../../../constants/model'
import ProgressiveImage from '../../../components/ProgressiveImage'
import React from 'react'
import { tagMethodGTM } from '../../../utils/common'

const CategoryBlogs = () => {
  const { blogsCategories } = useSelector(
      (store: ReducerModel) => store.home
    )
  const { id } = useParams()

  const [categoryName, setCategoryName] = useState('')

  //category blogs
  const categoryBlogs = React.useMemo(() => {    
    const blogs : any[] = []
    blogsCategories.filter((blog:any) => (blog.categoryName.toLowerCase().replace(/ /g,"_").replace(/\?/g, '').replace(/\-/g, "_") == id)).map((blogCategory: BlogsCategory) => {    
      blogCategory.allblogs.sort((a, b) => {
          return new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime()
        }).map((blog)=> {
          blogs.push(blog)
        })
        setCategoryName(blogCategory.categoryName)
    }) 
    return blogs
  }, [blogsCategories])

  //recent blogs if no blogs found after filter
  const recentBlogs = React.useMemo(() => {    
    const blogs : any[] = []
    categoryBlogs.slice(0,3).map((item, index) => {
      blogs.push(item)
    })
    return blogs
  }, [categoryBlogs])
  
  const [isBlogsLoaded, setIsBlogsLoaded] = useState(true)
  const [filteredBlogs, setFilteredBlogs] = useState<any[]>(categoryBlogs)  

  const dateOptions = ["Day", "Week", "Month"]
  const minuteOptions = ["1-5", "5-10", "10-15"]
  const sortByOptions = ["Latest First", "Oldest First"]
  const [filterByMin, setFilterByMin] = useState('')
  const [filterByDate, setFilterByDate] = useState('')
  const [displayDate, setdisplayDate] = useState(true)
  const [displayMins, setdisplayMins] = useState(true)
      
  const isSmall = useMediaQuery('(min-width:899px)');

  useEffect(() => {            
    window.scrollTo(0, 0)   
  },[])          

  //filter blogs by date 
  const handleDate = (date: any) => {   
    tagMethodGTM(categoryName, "Matter Blog " + categoryName + " - Filter By", "Click", date.target.value) 
    setFilterByDate(date.target.value)
    setdisplayDate(false)
    setIsBlogsLoaded(false)
      
    const filterBlogs = filterBlogsByDate(categoryBlogs, date.target.value)        

    if(filterByMin) {
      const combineFilteredBlogs = filterBlogsByMins(filterBlogs, filterByMin)        
      setFilteredBlogs(combineFilteredBlogs)
    } else {
      setFilteredBlogs(filterBlogs)
    } 
  }    
    
  //filter blogs by read time
  const handleMins = (mins: any) => {
    tagMethodGTM(categoryName, "Matter Blog " + categoryName + " - Filter By", "Click", mins.target.value)
    setFilterByMin(mins.target.value)
    setdisplayMins(false)
    setIsBlogsLoaded(false)

    const filterBlogs = filterBlogsByMins(categoryBlogs, mins.target.value)        

    if(filterByDate) {
      const combineFilteredBlogs = filterBlogsByDate(filterBlogs, filterByDate)        
      setFilteredBlogs(combineFilteredBlogs)
    } else {
      setFilteredBlogs(filterBlogs)
    }
  }

  const filterBlogsByMins = (blogs: any, mins: string) => {
    const filterBlogs =  blogs.filter((item: any) => {
      if(mins === minuteOptions[0])
        return item.readTime >= 1 && item.readTime < 5
      if(mins === minuteOptions[1])
        return item.readTime >= 5 && item.readTime < 10
      if(mins === minuteOptions[2])
        return item.readTime >= 10 && item.readTime < 15
      })
    return filterBlogs
  }

  const filterBlogsByDate = (blogs: any, date: string) => {
    const filterBlogs = blogs.filter((item:any) => {
      if(date === dateOptions[0])
        return new Date(item.publishDate) >= subtractDay()
      if(date === dateOptions[1]) {
        return new Date(item.publishDate) >= subtractWeeks()
      }            
      if(date === dateOptions[2]) {
        const month = subtractMonths(new Date(), 1);            
        return new Date(item.publishDate) >= month
      }                    
    })
    return filterBlogs
  }

  //to find the date of a month ago
  const subtractMonths = (date: Date, months: number) => {
    date.setMonth(date.getMonth() - months);
    return date;
  }

  //to find date of a week ago
  const subtractWeeks = () => {
    const now = new Date();
    return new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 7,
    );
  }

  //one day ago
  const subtractDay = (date = new Date()) => {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);
    return previous;
  }  
    
  //sort blogs by publish date
  const handleSortBy = (sortBy: any) => {    
    tagMethodGTM(categoryName, "Matter Blog " + categoryName + " - Sort By", "Click", sortBy.target.value) 
    setIsBlogsLoaded(false)       

    let sortedBlogs : any[] = []
    if(sortBy.target.value === sortByOptions[0]) {
      sortedBlogs = filteredBlogs.sort((a,b) => {          
        return new Date(b.publishDate).getTime() - new Date(a.publishDate).getTime()
      })  
    } else if(sortBy.target.value === sortByOptions[1]) {
      sortedBlogs = filteredBlogs.sort((a,b) => {          
        return new Date(a.publishDate).getTime() - new Date(b.publishDate).getTime()
      })
    }        
    setFilteredBlogs([...sortedBlogs])
  }  

  const handleClearDate = () => {
    setdisplayDate(true)
    setFilterByDate('')
    if(!displayDate && displayMins) {
      setFilteredBlogs(categoryBlogs)      
    } else if(!displayDate) {
      const filterBlogs = filterBlogsByMins(categoryBlogs, filterByMin)
      setFilteredBlogs(filterBlogs)
    }
  }

  const handleClearMins = () => {
    setdisplayMins(true)   
    setFilterByMin('')
    if(displayDate && !displayMins) {
      setFilteredBlogs(categoryBlogs)      
    } else if(!displayMins) {
      const filterBlogs = filterBlogsByDate(categoryBlogs, filterByDate)
      setFilteredBlogs(filterBlogs)
    }
  }

  const ClearDateIcon = () => (
    <>
      {displayDate ? "" 
                  : <img className="pointer" src={Icons.CloseBlackIcon} alt='Caret Black Color Icon' onClick={handleClearDate}/> }
    </>    
  )

  const ClearMinsIcon = () => (
    <>
      {displayMins ? ""
                  : <img className="pointer" src={Icons.CloseBlackIcon} alt='Caret Black Color Icon' onClick={handleClearMins}/> }
    </>    
  )

  const openBlog = (title: string) => {
    let blogTitle = title.toLowerCase().replace(/ /g,"_").replace(/\?/g, '').replace(/\-/g, "_")
    window.open('/blogs/' + blogTitle, '_blank')    
  }

  return (
    <div className="blogs-page-wrapper">
      <Header />
      <h1 className="visuallyHidden">Category Blogs</h1>        

      <div className="blog-data-content content-container">        
        <div className="category-title">
          <h5 className="monument large black mb-16 mb-sm-20 mb-xs-8">
            {categoryName}
          </h5>              
        </div>   
        <div className={filteredBlogs.length > 0 ? "filter-options" : "filter-options-error"}>        
          <Grid container spacing={11}>
            <Grid item xs={12} md={8} lg={9} className='pl-16'>                
              <div className="filters">
                <p className="filter-by">Filter by:</p>                       
                <div className="filter-dropdown">           
                  <Select
                    className="monument"
                    onChange={handleDate}
                    variant="standard"
                    disableUnderline    
                    startAdornment = {
                      <>
                        <InputAdornment position="start">    
                          {displayDate && <><p className='adornmentDate'>Date</p>
                            <img src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' /></>
                          }                                                    
                        </InputAdornment>
                      </>
                    }
                    IconComponent={ClearDateIcon}                    
                  >                
                    {dateOptions.map((option) => (
                      <MenuItem                    
                        key={option}
                        value={option}
                        className="dropdown-options"
                      >
                        <p className="small black">1 {option}</p>                    
                      </MenuItem>
                    ))}
                  </Select>   
                  <Select
                    className="monument"
                    onChange={handleMins}                
                    variant="standard"
                    disableUnderline    
                    startAdornment={
                      <>
                        <InputAdornment position="start">    
                          {displayMins && <><p className='adornmentReadTime'>Read Time</p>
                          <img src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' /></>}
                        </InputAdornment>
                      </>
                    }
                    IconComponent={ClearMinsIcon}        
                  >                
                    {minuteOptions.map((option) => (
                      <MenuItem                    
                        key={option}
                        value={option}
                        className="dropdown-options"
                      >
                        <p className="small black">{option} mins</p>
                      </MenuItem>
                    ))}
                  </Select>                        
                </div>                     
              </div>                                   
            </Grid>
            <Grid item={isSmall} xs={12} md={4} lg={3} className="sort-by-section">       
              <div className="filters">
                <p className="sort-by">Sort by:</p>
                <Select
                  className="monument"
                  onChange={handleSortBy}                
                  variant="standard"
                  defaultValue={sortByOptions[0]}
                  disableUnderline           
                  startAdornment={
                    <>
                      <InputAdornment className="sort-by-icon" position="start">                            
                        <img className="caret-icon" src={Icons.DownCaretBlackIcon} alt='Caret Black Color Icon' />
                      </InputAdornment>
                    </>
                  }  
                >                
                  {sortByOptions.map((option) => (
                    <MenuItem                    
                      key={option}
                      value={option}
                      className="dropdown-options"
                    >
                      <p className="small black">{option}</p>
                    </MenuItem>
                  ))}
                </Select>   
              </div>     
            </Grid>          
          </Grid>
        </div>         
            
        {/* display filtered and initial load blogs */}
        {filteredBlogs.length > 0 ? 
          <Grid container spacing={{xs: 1.5, sm: 3}} rowSpacing={{ md: 10 }}>
            {(isBlogsLoaded ? categoryBlogs : filteredBlogs).map((item, index)=>                 
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} 
                  onClick={()=> { 
                    openBlog(item.title)
                    tagMethodGTM(categoryName, "Matter Blog " + categoryName, "Click", item.title) 
                    }}>
                <div className="monument pointer blog-posts">
                  <ProgressiveImage alt="Matter Category Blogs"                    
                    className="more-posts-blog-img" 
                    imgSrc={process.env.STRAPI_URL + item.matterBlogImageWeb} 
                    previewSrc={process.env.STRAPI_URL + item.matterBlogThumbNailImageWeb} />                  
                  <p className="date mr-8">{moment(item.publishDate).format('MMM DD, YYYY')}</p>
                  <span className="dot"></span>                  
                  <p className="date ml-8">{item.readTime} min read</p>
                  <p className="blog-data-title mt-8">
                        {item.title}
                  </p>                
                  <p className="blog-description">
                    {item.blogIntroduction} 
                  </p>
                </div>
                </Link>
              </Grid>   )}             
          </Grid> : 
          // display recent blogs if no blogs found after filter
          <div className="recent-blogs">
            <p className="filter-error-msg">Opps, we couldn’t find a  blog try adjusting your filter.</p>
            <p className="filter-error-msg recent-blogs-title">Recent Blogs</p>
            <Grid container spacing={{xs: 1.5, sm: 3}}>
              {recentBlogs.map((item, index) =>                 
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Link style={{ textDecoration: 'none' }} target={'_blank'} to= {''} onClick={()=> openBlog(item.title)}>
                  <div className="monument pointer blog-posts">
                    <ProgressiveImage alt="Matter Category Blogs"                    
                      className="more-posts-blog-img" 
                      imgSrc={process.env.STRAPI_URL + item.matterBlogImageWeb} 
                      previewSrc={process.env.STRAPI_URL + item.matterBlogThumbNailImageWeb} />                    
                    <p className="date mr-8">{moment(item.publishDate).format('MMM DD, YYYY')}</p>
                    <span className="dot"></span>                    
                    <p className="date ml-8">{item.readTime} min read</p>
                    <p className="blog-data-title mt-8">
                          {item.title}
                    </p>                
                    <p className="blog-description">
                      {item.blogIntroduction} 
                    </p>
                  </div>
                  </Link>
                </Grid>   
              )}             
            </Grid>
          </div>}
      </div>        
      <Footer />
    </div>    
  )
}

export default CategoryBlogs
