import React, { Suspense, useEffect } from 'react'
import moment from 'moment'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import {
  BrowserRouter,
  Route,
  Switch,
  useLocation,
  Redirect,
} from 'react-router-dom'
import { Provider, useDispatch } from 'react-redux'
import difference from 'lodash/difference'
import mapValues from 'lodash/mapValues'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { IntlProvider } from './utils/reactIntl'
import { persistor } from './store'
import defaultMessages from './translations/en.json'
import messagesInLocale from './translations/fr.json'
import config from './config'
import AppLoader from './components/AppLoader'
import RYI from './modules/RYI'
import CookieConsent from 'react-cookie-consent'
import RYA from './modules/RYA'
import { Box } from '@mui/system'
const MatterAeraComponent = React.lazy(() => import('./modules/MatterAera'))
const OnboardingComponent = React.lazy(() => import('./modules/onboarding'))
const ConfigureComponent = React.lazy(() => import('./modules/prebook'))
const HomeComponent = React.lazy(() => import('./modules/home'))
import AccountComponent from './modules/account'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

import homeActions from './actions/homeActions'
const ErrorComponent = React.lazy(() => import('./modules/errorRoute'))

// import MyAccountHome from './modules/account/home';
const SupportComponent = React.lazy(() => import('./modules/support'))
const FaqComponent = React.lazy(() => import('./modules/faq'))
const PartnerWithUsComponent = React.lazy(
  () => import('./modules/partnerWithUs')
)
const BecomeAPartnerComponent = React.lazy(
  () => import('./modules/partnerWithUs/becomeAPartner')
)
const BecomeAPartnerFromDetailsComponent = React.lazy(
  () => import('./modules/partnerWithUs/becomeAPartner/ChannelPartnerForm')
)

// const BecomeASupplierComponent = React.lazy(() => import('./modules/partnerWithUs/becomeASeller'))
const PrebookingConfirmedComponent = React.lazy(
  () => import('./modules/prebook/prebookingConfirmed')
)
const PrebookingStatusComponent = React.lazy(
  () => import('./modules/prebook/prebookStatus')
)
const PrivacyPolicyComponent = React.lazy(
  () => import('./modules/legal/privacyPolicy')
)

const TermsAndConditionsComponent = React.lazy(
  () => import('./modules/legal/termsAndConditions')
  )
const TCOCalculator = React.lazy(
  () => import('./modules/tcoCalculator')
)
// import CompanionAppFAQ from './modules/companionFAQ'
// import CompanionAppSupport from './modules/companionSupport'
// import CompanionCautionNotice from './modules/legal/companionApp/CompanionCautionNotice'
// import CompanionMobilePrivacyPolicy from './modules/legal/companionApp/CompanionMobilePrivacyPolicy'
// import CompanionPrebookTermsAndConditions from './modules/legal/companionApp/CompanionPreBookingTerms'
// import CompanionMobileTermsAndConditions from './modules/legal/companionApp/CompanionMobileTermsAndConditions'

const SuperEarlyBirdTermsComponent = React.lazy(
  () => import('./modules/legal/superEarlyBirdTerms')
)
const promotionalCodeTermsComponent = React.lazy(
  () => import('./modules/legal/promotionalCodeTerms')
)
const OnlinePreBookingTermsComponent = React.lazy(
  () => import('./modules/legal/onlinePreBookingTerms')
)
const MissionNoEmissionOfferComponent = React.lazy(
  () => import('./modules/legal/missionNoEmissionOffer')
)
const CautionNotice = React.lazy(() => import('./modules/legal/cautionNotice'))
const InnovationHubComponent = React.lazy(
  () => import('./modules/innovationHub')
)
const WhoWeAreComponent = React.lazy(() => import('./modules/whoWeAre'))
import BlogsComponent from './modules/blogs'
import JoinWaitlistTC from './modules/legal/joinWaitlistTC'

// const DealerLocator = React.lazy(
//   () => import('./modules/dealerLocator')
// )

const addMissingTranslations = (
  sourceLangTranslations: any,
  targetLangTranslations: any
) => {
  const sourceKeys = Object.keys(sourceLangTranslations)
  const targetKeys = Object.keys(targetLangTranslations)
  const missingKeys = difference(sourceKeys, targetKeys)

  const addMissingTranslation = (translations: any, missingKey: any) => ({
    ...translations,
    [missingKey]: sourceLangTranslations[missingKey],
  })

  return missingKeys.reduce(addMissingTranslation, targetLangTranslations)
}

const isDefaultLanguageInUse = config.locale === 'en'

const messages = isDefaultLanguageInUse
  ? defaultMessages
  : addMissingTranslations(defaultMessages, messagesInLocale)

const isTestEnv = process.env.NODE_ENV === 'test'

// Locale should not affect the tests. We ensure this by providing
// messages with the key as the value of each message.
const testMessages = mapValues(messages, (val, key) => key)
const localeMessages = isTestEnv ? testMessages : messages

const setupLocale = () => {
  if (isTestEnv) {
    // Use english as a default locale in tests
    config.locale = 'en'
    return
  }

  moment.locale(config.locale)
}

interface Props {
  appStore: any
}

const AppRoutes = () => {
  const { pathname } = useLocation()
  const dispatch: Function = useDispatch()

  useEffect(() => {
    dispatch(homeActions.getBannerStrapiApi())
    dispatch(homeActions.getAppConfig())
  }, [])

  return (
    <Suspense
      fallback={<AppLoader />}
      // fallback={<Box sx={{height:"100vh" , background: "red"}}></Box>}
    >
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <Route path={'/'} exact={true} component={HomeComponent} />
        <Route path={'/login'} exact={true} component={OnboardingComponent} />
        <Route path={'/prebook'} exact={true} component={ConfigureComponent} />
        {/* <Route
          path={'/dealer-locator'}
          exact={true}
          component={DealerLocator}
        /> */}
        <Route
          path={'/prebookingStatus'}
          exact={true}
          component={PrebookingStatusComponent}
        />
        <Route
          path={'/prebooking-confirmation'}
          exact={true}
          component={PrebookingConfirmedComponent}
        />
        <Route
          path={'/motorbike'}
          exact={true}
          component={MatterAeraComponent}
        />
        <Route
          path={'/my-account'}
          exact={true}
          render={() => <AccountComponent />}
        />
        <Route
          path={'/my-account/*'}
          exact={true}
          render={() => <AccountComponent />}
        />
        <Route
          path={'/privacy-policy'}
          exact={true}
          component={PrivacyPolicyComponent}
        />
        <Route
          path={'/terms-and-conditions'}
          exact={true}
          component={TermsAndConditionsComponent}
        />
        <Route
          path={'/promotional-code-terms'}
          exact={true}
          component={promotionalCodeTermsComponent}
        />
        <Route
          path={'/mission-noemission-offer-terms'}
          exact={true}
          component={MissionNoEmissionOfferComponent}
        />
        <Route
          path={'/online-prebooking-terms'}
          exact={true}
          component={OnlinePreBookingTermsComponent}
        />
        <Route path={'/register-your-interest'} exact={true} component={RYI} />
        <Route path={'/reserve-your-aera'} exact={true} component={RYA} />
        <Route path={'/support'} exact={true} component={SupportComponent} />
        <Route path={'/faq'} exact={true} component={FaqComponent} />
          {/* <Route
          path={'/online-prebooking-terms-app'}
          exact={true}
          component={CompanionPrebookTermsAndConditions}
        /> */}
        <Route
          path={'/caution-notice'}
          exact={true}
          component={CautionNotice}
        />
         {/* <Route
          path={'/caution-notice-app'}
          exact={true}
          component={CompanionCautionNotice}
        /> */}
        
         <Route
          path={'/partner-with-us'}
          exact={true}
          component={PartnerWithUsComponent}
        />
        <Route
          path={'/become-a-partner'}
          exact={true}
          component={BecomeAPartnerComponent}
        />
        <Route
          path={'/become-a-partner_from'}
          exact={true}
          component={BecomeAPartnerFromDetailsComponent}
        />

        {/* <Route
          path={'/become-a-supplier'}
          exact={true}
          component={BecomeASupplierComponent}
        /> */}

        <Route
          path={'/innovation-hub'}
          exact={true}
          component={InnovationHubComponent}
        />

        <Route
          path={'/who-we-are'}
          exact={true}
          component={WhoWeAreComponent}
        />
        <Route path={'/blogs'} exact={true} render={() => <BlogsComponent />} />
        <Route
          path={'/blogs/*'}
          exact={true}
          render={() => <BlogsComponent />}
        />
        <Route
          path={'/terms-and-conditions-joinwaitlist'}
          exact={true}
          render={() => <JoinWaitlistTC />}
        />
        <Route path={'/tco-calculator'} exact={true} component={TCOCalculator} />        
         {/* <Route
          path={'/privacy-policy-app'}
          exact={true}
          component={CompanionMobilePrivacyPolicy}
        /> */}
         {/* <Route
          path={'/terms-and-conditions-app'}
          exact={true}
          component={CompanionMobileTermsAndConditions}
        />
        <Route
          path={'/support-app'}
          exact={true}
          component={CompanionAppSupport}
      />
       <Route
          path={'/faq-app'}
          exact={true}
          component={CompanionAppFAQ}
        /> */}
         <Route
          path={'/*'}
          exact={true}
          component={ErrorComponent}
        />

      </Switch>
    </Suspense>
  )
}
export const App = (props: Props) => {
  const { appStore } = props
  const [showMessage, setShowMessage] = React.useState(false)

  setupLocale()

  const myCache = createCache({
    key: 'my-prefix-key',
    nonce: 'ec3e9f95-0171-4a86-8fb4-9cd7ba53138c',
  })

  React.useEffect(() => {
    // Userback('40462|81002|PpxdhcU9W4cSdlTfUhAVxEX21');
  }, [])
  if (process.env.mode === 'production') {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
    console.warn = () => {}
  }

  return (
    <IntlProvider
      locale={config.locale}
      messages={localeMessages}
      textComponent="span"
    >
      <Provider store={appStore}>
        <PersistGate persistor={persistor}>
          <CacheProvider value={myCache}>
            <HelmetProvider>
              <Helmet>
                <script src={process.env.BILLDESK_SCRIPT} type="module" />
                <script
                  src={process.env.BILLDESK_SDK_SCRIPT}
                  noModule={undefined}
                />
                <link rel="stylesheet" href={process.env.BILLDESK_CSS} />
              </Helmet>
              <BrowserRouter
                ref={(route: any) => {
                  if (route && route.history) {
                    //@ts-ignore
                    global.routeRef = route.history
                  }
                }}
              >
                <div className="visuallyHidden">
                  <a href="/prebooking-confirmation" />
                  <a href="/motorbike" />
                  <a href="/login" />
                  <a href="/prebookingStatus" />
                  <a href="/promotional-code-terms" />
                  <a href="/mission-noemission-offer-terms" />
                  <a href="/my-account" />
                  <a href="/become-a-partner" />
                  <a href="/register-your-interest" />
                  <a href="/support" />
                  <a href="/faq" />
                  <a href="/blogs" />
                  <a href="/innovation-hub" />
                  
                  
                </div>
                <AppRoutes />
              </BrowserRouter>
              <CookieConsent
                location="bottom"
                declineButtonText="Reject"
                buttonText="Accept"
                enableDeclineButton
                // flipButtons
                cookieName="myAwesomeCookieName2"
                style={{
                  background: 'rgb(39, 39, 39)',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0 12px',
                  borderTop: '1px solid white',
                }}
                buttonStyle={{
                  background: 'rgb(3, 106, 255)',
                  border: '0px',
                  borderRadius: '5px',
                  boxShadow: 'none',
                  color: 'white',
                  cursor: 'pointer',
                  flex: '0 0 auto',
                  padding: '5px 10px',
                  margin: '8px 10px',
                  fontSize: '14px',
                }}
                declineButtonStyle={{
                  background: 'rgb(74, 73, 73)',
                  border: '0px',
                  borderRadius: '5px',
                  boxShadow: 'none',
                  color: 'rgb(255, 255, 255)',
                  cursor: 'pointer',
                  flex: '0 0 auto',
                  padding: '5px 10px',
                  margin: '8px 10px',
                  fontSize: '14px',
                }}
                buttonWrapperClasses="buttonWrapperClasss"
                // expires={150}
              >
                <div className="flex" style={{ margin: '12px 0' }}>
                  <div>
                    <p style={{ fontSize: '12px' }}>
                      We use cookies to collect your data in order to enhance
                      your browsing experience, serve personalized ads or
                      contents, and analyse your traffic. By clicking on
                      “Accept”, you consent to the use of cookies to collect
                      your data which shall be used in accordance with our{' '}
                      <a
                        href="/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#0d6efd', textDecoration: 'none' }}
                      >
                        Privacy Policy
                      </a>{' '}
                      .
                    </p>

                    <p style={{ fontSize: '12px' }} className="mt-10">
                      By clicking on “Reject”, you reject the use of all
                      non-essential cookies. You may also restrict the use of
                      cookies by changing your browser settings. However,
                      restricting the use of the cookies may impact your
                      experience of the site and the services we offer.
                    </p>
                  </div>
                </div>
              </CookieConsent>
            </HelmetProvider>
          </CacheProvider>
        </PersistGate>
      </Provider>
    </IntlProvider>
  )
}

export default App
