import React, { useState } from 'react'
// import Images from '../../utils/images'
import Icons from '../../utils/icons'

import './footer.scss'
import { Link, Grid, Button } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import prebookingActions from '../../actions/prebookingActions'
import moment from 'moment'
import Toaster from '../Toaster'
import { tagMethodGTM } from '../../utils/common'
import Images from '../../utils/images'
import { AppData } from '../../constants'
import { ReducerModel } from '../../reducers/reducerModel'

const Footer = ({ fromHome }: any) => {
  const history = useHistory()
  const dispatch: any = useDispatch()
  const currentPathName = history.location.pathname
  const isFAQ = history.location.pathname.includes('faq')
  const isContact = history.location.pathname.includes('support')
  const isPartner = history.location.pathname.includes('become-a-partner')
  const isAtRYI = history.location.pathname.includes('register-your-interest')
  const { appConfig } = useSelector((store: ReducerModel) => store.home)

  const [messageToast, setMessageToast] = useState('')

  const onClickPrebook = () => {
    dispatch(
      prebookingActions.prebookValidate(
        (data: any) => {
          if (data.preBookEnabled) {
            history.push('/prebook')
          } else {
            setMessageToast(`PRE-BOOKING STARTS 17th May`)
          }
        },
        () => {}
      )
    )
    setMessageToast('')
  }

  let routedFooterTabs = JSON.parse(
    JSON.stringify(AppData.footerBannerRoutes)
  ).filter((item, index) => {
    if (index == 0) {
      const joinWaitListRoutes = [
        { title: 'Join Waitlist', route: '/register-your-interest' },
      ]
      const prebookRoutes = [
        { title: 'Pre-book Now', route: '/prebook' },
        { title: 'Register Your Interest', route: '/register-your-interest' },
      ]
      item.routes = appConfig.joinWaitListkey
        ? joinWaitListRoutes
        : prebookRoutes
    }
    return !item.routes.find((routeItem) => currentPathName == routeItem.route)
  })
  if (routedFooterTabs.length == 4) {
    routedFooterTabs.pop()
  }

  return (
    <div id="footerSec">
      {messageToast && <Toaster successMessage={messageToast} failMessage="" />}
      <section className="explore-section">
        <h3 className="monument title">
          Explore the Era of <br /> Matter with us.
        </h3>
        <div className="explore-container content-container">
          <Grid container spacing={{ xs: 2, lg: 5 }} className="for-desktop">
            {routedFooterTabs.map((item) => (
              <Grid key={item.title} item xs={4}>
                <div className="explore-card">
                  <div className="card-content">
                    <div>
                      <div className="icon-cover mb-20">
                        <img
                          src={item.icon}
                          alt="Prebook Matter Aera Bike Image"
                        />
                      </div>
                      <h6 className="small mb-12 monument white">
                        {item.title}
                      </h6>
                      <p className="small mb-32 mb-md-20 thin subTextLight">
                        {item.description}
                      </p>
                    </div>
                    <ul>
                      {item.routes.map((routeMap) => (
                        <li
                          key={routeMap.route}
                          onClick={() => {
                            tagMethodGTM(
                              'Explore the Era of Matter with us.',
                              'Matter Explore the Era of Matter with us.',
                              'Click',
                              item.title + ' - ' + routeMap.title
                            )
                          }}
                        >
                          <Link
                            className="primary bold"
                            href={`${routeMap.route}`}
                          >
                            {routeMap.title}
                            <i className="arrow-blue-sm-icon ml-5"></i>
                          </Link>
                        </li>
                      ))}
                      {/* <li className="mb-20 mb-md-8">
                      <Link className="primary bold" href="prebook">
                        Pre-book Now <i className="arrow-blue-sm-icon ml-5"></i>
                      </Link>
                    </li>
                    <li>
                      {!isAtRYI && (
                        <Link
                          className="primary bold"
                          href="/register-your-interest"
                        >
                          Register Your Interest{' '}
                          <i className="arrow-blue-sm-icon ml-5"></i>
                        </Link>
                      )}
                    </li> */}
                    </ul>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>

          <div className="explore-card for-mobile">
            <div className="card-content">
              <Grid container spacing={{ xs: 6 }}>
                {routedFooterTabs.map((item) => {
                  return (
                    <Grid item key={item.title} xs={12}>
                      <div className="card-content-detail">
                        <div className="icon-cover mb-20">
                          <img
                            src={item.icon}
                            alt="Prebook Matter Aera Bike Image"
                          />
                        </div>
                        <div className="card-info">
                          <h6 className="small mb-4 monument white">
                            {item.title}
                          </h6>
                          <p className="small mb-12 thin subTextLight">
                            {item.description}
                          </p>

                          {item.routes.map((routeItem, index) => {
                            return (
                              <Link
                                key={routeItem.title}
                                href={`${routeItem.route}`}
                                className={`primary bold ${
                                  index % 2 == 0 ? 'mr-20' : ''
                                }`}
                                onClick={() => {
                                  tagMethodGTM(
                                    'Explore the Era of Matter with us.',
                                    'Matter Explore the Era of Matter with us.',
                                    'Click',
                                    item.title + ' - ' + routeItem.title
                                  )
                                }}
                              >
                                {routeItem.title}{' '}
                                <i className="arrow-blue-sm-icon"></i>
                              </Link>
                            )
                          })}
                          {/* <Link className="primary bold mr-20">
                        Pre-book Now <i className="arrow-blue-sm-icon"></i>
                      </Link>
                      {!isAtRYI && (
                        <Link className="primary bold">
                          Register Your Interest{' '}
                          <i className="arrow-blue-sm-icon"></i>
                        </Link>
                      )} */}
                        </div>
                      </div>
                    </Grid>
                  )
                })}
              </Grid>
            </div>
          </div>

          {/* {false && (
            <div className="explore-card">
              <div className="card-content">
                <Grid container spacing={{ xs: 2, sm: 3, md: 4, lg: 5 }}>
                  <Grid
                    item
                    xs={isAtRYI ? 12 : 6}
                    sm={6}
                    lg={4}
                    className="link-detail"
                  >
                    <div className="icon-cover mb-15 mb-xs-10">
                      <img
                        src={Icons.PrebookIcon}
                        alt="Prebook Matter Aera Bike Image"
                      />
                    </div>
                    <h6 className="small mb-8 monument">Prebook Now!</h6>
                    <p className="small mb-16 mb-xs-8 thin subTextLight">
                      Grab your rider’s pass to the 22nd century’s dynamic
                      motorbiking experience! Get your Hands-on the priority
                      access to AERA, now!
                    </p>
                    <Link
                      onClick={() => {
                        tagMethodGTM(
                          `${fromHome ? 'Banner' : 'Prebook Now'}`,
                          `${
                            fromHome
                              ? 'Matter Explore the Era'
                              : 'Matter Prebook Now'
                          }`,
                          'Click',
                          'Prebook Now'
                        )

                        onClickPrebook()
                      }}
                      className="primary bold"
                    >
                      Prebook Now <i className="arrow-blue-sm-icon ml-5"></i>
                    </Link>
                  </Grid>
                  {!isAtRYI && (
                    <Grid item xs={6} lg={4} className="link-detail">
                      <div className="icon-cover mb-15 mb-xs-10">
                        <img
                          src={Icons.MessageIcon}
                          alt="Chat with Matter Expert Image"
                        />
                      </div>
                      <h6 className="small mb-8 monument">
                        Register your interest!
                      </h6>
                      <p className="small mb-16 mb-xs-8 thin subTextLight">
                        Don't miss out! Register your interest today and be the
                        first to know when our product is available.
                      </p>

                      <Link
                        onClick={() => {
                          tagMethodGTM(
                            `${fromHome ? 'Banner' : "I'm Interested`"}`,
                            `${
                              fromHome
                                ? 'Matter Explore the Era - Register your Interest'
                                : "Matter I'm Interested"
                            }`,
                            'Click',
                            "I'm Interested"
                          )
                          history.push('/register-your-interest')
                        }}
                        className="primary bold"
                      >
                        I’m Interested{' '}
                        <i className="arrow-blue-sm-icon ml-5"></i>
                      </Link>
                    </Grid>
                  )}
                </Grid>
              </div>
            </div>
          )} */}
        </div>
      </section>
      <footer className={isAtRYI ? 'no-banner' : ''}>
        <div className="footer-wrapper content-container">
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5.5}>
              <h2 className="monument mb-40 mb-md-28">
                Hey rider, wanna catch-up to have a word with us?
              </h2>
              <div className="say-hi-cover flex">
                <a
                  href="mailto: hello@matter.in"
                  target={'_blank'}
                  className="btn-gradiant-border with-black-bg large"
                  onClick={() => {
                    tagMethodGTM(
                      'Footer Navigation Menu',
                      'Matter Footer Navigation Menu - Hey Rider, wanna catch-up to have a word with us?',
                      'Click',
                      'Say Hii!'
                    )
                  }}
                >
                  Say Hi!{' '}
                  <img
                    className="ml-10"
                    src={Icons.NextChevronIcon}
                    alt="Next Caret Icon"
                  />
                </a>
              </div>
            </Grid>
            <Grid item xs={12} lg={6.5}>
              <div className="all-links-cover">
                <div className="white">
                  <h6 className="mb-8">Matter</h6>
                  <ul>
                    <li>
                      <Link
                        onClick={() => {
                          window.scrollTo(0, 0)
                          history.push('/who-we-are')
                          tagMethodGTM(
                            'Footer Navigation Menu',
                            'Matter Footer Navigation Menu',
                            'Click',
                            'Matter - Who we are'
                          )
                        }}
                      >
                        Who we are
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          window.scrollTo(0, 0)
                          history.push('/innovation-hub')
                          tagMethodGTM(
                            'Footer Navigation Menu',
                            'Matter Footer Navigation Menu',
                            'Click',
                            'Matter - Innovation hub'
                          )
                        }}
                      >
                        Innovation hub
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          window.scrollTo(0, 0)
                          history.push('/partner-with-us')
                          tagMethodGTM(
                            'Footer Navigation Menu',
                            'Matter Footer Navigation Menu',
                            'Click',
                            'Matter - Partner with us'
                          )
                        }}
                      >
                        Partner with us
                      </Link>
                    </li>

                    {/* <li>
                      <Link
                        onClick={() => {
                          window.scrollTo(0, 0)
                          tagMethodGTM("Hamburger Menu" , "Matter Hamburger Menu" , "Click" , "Find a Dealer")
                          history.push('/dealer-locator')
                        }}
                      >
                       Find a dealer
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        onClick={() => {
                          window.scrollTo(0, 0)
                          history.push('/become-a-partner')
                          tagMethodGTM(
                            'Footer Navigation Menu',
                            'Matter Footer Navigation Menu',
                            'Click',
                            'Matter - Become a channel partner'
                          )
                        }}
                      >
                        Become a channel partner
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          window.scrollTo(0, 0)
                          history.push('/blogs')
                        }}
                      >
                        Matter Stream
                      </Link>
                    </li>
                    {/* <li>
                        <Link
                          onClick={() => {
                            window.scrollTo(0, 0)
                            history.push('/become-a-partner')
                          }}
                        >
                          Become a channel partner
                        </Link>
                      </li> */}
                    {/* </li> */}
                    {/* <li>
                        <Link href="/support">Support</Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => {
                            const zoho = window['$zoho']
                            if (zoho) {
                              zoho.salesiq.floatwindow.open()
                            }
                          }}
                        >
                          Chat with us
                        </Link>
                      </li>
                      <li>
                        <Link href="mailto:customer.care@matter.in">
                          Email us
                        </Link>
                      </li> */}
                  </ul>
                </div>
                <div className="white">
                  <h6 className="mb-8">Care</h6>
                  <ul>
                    <li
                      onClick={() => {
                        tagMethodGTM(
                          'Footer Navigation Menu',
                          'Matter Footer Navigation Menu - Care',
                          'Click',
                          'Care - Support'
                        )
                      }}
                    >
                      <Link href="/support">Support</Link>
                    </li>
                    <li
                      onClick={() => {
                        tagMethodGTM(
                          'Footer Navigation Menu',
                          'Matter Footer Navigation Menu - Care',
                          'Click',
                          'Care - FAQ'
                        )
                      }}
                    >
                      <Link href="/faq">FAQ</Link>
                    </li>
                  </ul>
                </div>
                <div>
                  <h6 className="mb-8">Legal</h6>
                  <ul>
                    <li
                      onClick={() => {
                        tagMethodGTM(
                          'Footer Navigation Menu',
                          'Matter Footer Navigation Menu - Legals',
                          'Click',
                          'Legals - T&C of Use'
                        )
                      }}
                    >
                      <Link href="/terms-and-conditions">T&C of Use</Link>
                    </li>
                    <li
                      onClick={() => {
                        tagMethodGTM(
                          'Footer Navigation Menu',
                          'Matter Footer Navigation Menu - Legals',
                          'Click',
                          'Legals - Privacy Policy'
                        )
                      }}
                    >
                      <Link href="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li
                      onClick={() => {
                        tagMethodGTM(
                          'Footer Navigation Menu',
                          'Matter Footer Navigation Menu - Legals',
                          'Click',
                          'Legals - Join Waitlist T&C'
                        )
                      }}
                    >
                      <Link href="/terms-and-conditions-joinwaitlist">
                        Join Waitlist T&C
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        tagMethodGTM(
                          'Footer Navigation Menu',
                          'Matter Footer Navigation Menu - Legals',
                          'Click',
                          'Legals - Online Pre-Booking T&C'
                        )
                      }}
                    >
                      <Link href="/online-prebooking-terms">
                        Online Pre-Booking T&C
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        tagMethodGTM(
                          'Footer Navigation Menu',
                          'Matter Footer Navigation Menu - Legals',
                          'Click',
                          'Legals - Caution Notice'
                        )
                      }}
                    >
                      <Link href="/caution-notice">Caution Notice</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="social-links">
                <Link>
                  <IconButton
                    href="https://instagram.com/matter.in/"
                    target={'_blank'}
                    onClick={() => {
                      tagMethodGTM(
                        'Footer Navigation Menu',
                        'Matter Footer Navigation Menu - Social',
                        'Click',
                        'Social - Instagram'
                      )
                    }}
                    aria-label="Instagram"
                  >
                    <img src={Icons.InstaIcon} alt="Instagram Logo" />
                  </IconButton>
                </Link>
                <Link>
                  <IconButton
                    target="_blank"
                    href="https://www.facebook.com/MatterIndiaOfficial"
                    onClick={() => {
                      tagMethodGTM(
                        'Footer Navigation Menu',
                        'Matter Footer Navigation Menu - Social',
                        'Click',
                        'Social - Facebook'
                      )
                    }}
                    aria-label="Facebook"
                  >
                    <img src={Icons.FacebookIcon} alt="Facebook Logo" />
                  </IconButton>
                </Link>
                <Link>
                  <IconButton
                    target="_blank"
                    href="https://www.youtube.com/@MatterIndia"
                    onClick={() => {
                      tagMethodGTM(
                        'Footer Navigation Menu',
                        'Matter Footer Navigation Menu - Social',
                        'Click',
                        'Social - YouTube'
                      )
                    }}
                    aria-label="Youtube"
                  >
                    <img src={Icons.YoutubeIcon} alt="Youtube Logo" />
                  </IconButton>
                </Link>
                {/* <Link>
                  <IconButton
                    target="_blank"
                    href="https://twitter.com/matter_india?lang=en"
                    onClick={() => {
                      tagMethodGTM(
                        'Footer Navigation Menu',
                        'Matter Footer Navigation Menu - Social',
                        'Click',
                        'Social - Twitter'
                      )
                    }}
                    aria-label="X"
                  >
                    <img src={Icons.XIcon} alt="X Logo" />
                  </IconButton>
                </Link> */}
                <Link>
                  <IconButton
                    target="_blank"
                    href="https://www.linkedin.com/company/matterindia/"
                    onClick={() => {
                      tagMethodGTM(
                        'Footer Navigation Menu',
                        'Matter Footer Navigation Menu - Social',
                        'Click',
                        'Social - LinkedIn'
                      )
                    }}
                    aria-label="Linkedin"
                  >
                    <img src={Icons.LinkedinIcon} alt="Linkedin Logo" />
                  </IconButton>
                </Link>
              </div>
              <p className="ex-small right subTextLight all-rights mt-16 mt-md-8">
                @2023. Matter Motor Works Pvt. Ltd. All rights reserved
              </p>
            </Grid>
          </Grid>
        </div>
      </footer>
    </div>
  )
}

export default Footer
