import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import './topbanner.scss'
import HomeModal from '../Popup/HomeModal'
import CauCautionNoticeModal from '../Popup/CautionNoticeModal'
import { isMobile } from '../../modules/MatterAera/sections/splash'

import Icons from '../../utils/icons'
import Images from '../../utils/images'
import { numberWithCommas, tagMethodGTM } from '../../utils/common'
import { useSelector } from 'react-redux'
import { ReducerModel } from '../../reducers/reducerModel'

const TopBanner = () => {
  const history = useHistory()
  const {topHomeBanner , appConfig} = useSelector((store : ReducerModel) => store.home)
  const [isModalOpen, setModalOpen] = React.useState(false)
  const [isCautionModalOpen, setCautionModalOpen] = React.useState(false)

  const handleModalOpen = () => {
    setModalOpen(true)
  }
  const handleModalClose = () => {
    setModalOpen(false)
  }
  const handleCautionModalOpen = () => {
    setCautionModalOpen(true)
  }

  const handleCautionModalClose = () => {
    setCautionModalOpen(false)
  }

  return (
    <div className='top-banner-box'>
      <div className='slider-container'>
        <div id="top-banner-sec1" className="top-banner slide">
          <div className="top-banner-inner-box">
            <p className="small for-desktop">
            { topHomeBanner.desktopmessage }
            </p>
            <p className="small for-mobile">
              <span className="underline pointer" onClick={handleModalOpen}>
              {topHomeBanner.mobilemessage}
              </span>
            </p>
            <a
                onClick={() => {
                  if(!appConfig.joinWaitListkey) {
                    tagMethodGTM("Banner" , "Matter HomePage Banner - Pre Booking Open " , "Click" , " Register to Pre-Book")
                    handleModalOpen()
                  } else {
                    tagMethodGTM("Pop - Up" , "Matter - Pop - Up" , "Click" , "Join Waitlist")
                  }
            }}
            style={{textDecoration:"none"}}
            href={ appConfig.joinWaitListkey ? '/register-your-interest' : null}
              >
            
            {appConfig.joinWaitListkey ?  "Join Waitlist" : "Pre-Book Now"}
              {/* <i className="arrow-blue-sm-icon ml-0 " ></i> */}
              <img
                className="ml-5"
                src={Icons.NextChevronIcon}
                alt=""
              />
            </a>
          </div>
          <div className="slanter_by"></div>
        </div>
        <div
          onClick={handleCautionModalOpen}
          id="top-banner-sec2"
          className="top-banner yellow slide">
            <div className="top-banner-inner-box">
              <p className="small text-caution">
                <img src={Icons.CautionBannerIcon} />
                {' '}<span className='bold mx-1'>ATTENTION!</span>{' '}
                <span>Beware of fraudulent activities in our name.</span>
              </p>
              <div className="slanter_yb"></div>
            </div>
        </div>
      </div>
      <HomeModal open={isModalOpen} handleClose={handleModalClose} />
      <CauCautionNoticeModal open={isCautionModalOpen} handleClose={handleCautionModalClose} />
    </div>
  )
}

export default TopBanner
