import React, { useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Images from '../../utils/images'
import './../RYI/ryi.scss'
import Icons from '../../utils/icons'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Form, Field, Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import {
  postGeoLocaiton,
  registerInterestUser,
} from '../../actions/registerInterest'
import { useDispatch, useSelector } from 'react-redux'
import { phonePreventText, reserveYourAera } from '../../schema'
import { Dialog, InputAdornment, MenuItem, Link } from '@mui/material'
import { ReducerModel } from '../../reducers/reducerModel'
import { ActionTypes } from '../../constants'
import { RootState } from '../../reducers'
import Toaster from '../../components/Toaster'
import { decryptData, handlePasteMethod } from '../../utils/common'
import HeadlessComponent from '../../components/HeadlessComponent'

interface IntialValues {
  firstName: string
  lastName: string
  phone: string
  email: string
  receiveNotifications?: boolean
  whatsAppNo?: string
  city: string
  state: string
  teams?: boolean
  bikeModel: any
  bikeColor: any
}

const RYA = () => {
  const [showForm, setShowForm] = useState(true)
  const dispatch: Function = useDispatch()
  const history = useHistory()

  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [confirmationScreen, setConfirmationScreen] = useState(false)
  const [limitStatus, setLimitStatus] = useState(false)
  const [errorToastMessage, seterrorToastMessage] = useState('')

  const { availableAeras, createPrebookingAera } = useSelector(
    (store: ReducerModel) => store.createPreBooking
  )

  const {currentUserDetails} = useSelector((store: RootState) => store.userDetails)

  const userData = React.useMemo(() => {
    return decryptData(currentUserDetails)
  },[currentUserDetails])

  const intialVal: IntialValues = {
    firstName: userData?.firstName ? userData?.firstName : '',
    lastName: userData?.firstName ? userData?.lastName : '',
    city: '',
    state: '',
    phone: userData?.phone ? userData?.phone : '',
    email: userData?.email ? userData?.email : '',
    receiveNotifications: true,
    whatsAppNo: userData?.whatsAppNo ? userData?.whatsAppNo : userData?.phone,
    teams: false,
    bikeModel: '',
    bikeColor: '',
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)

    navigator.geolocation.getCurrentPosition((position) => {
      let lat = position.coords.latitude
      let long = position.coords.longitude

      dispatch(
        postGeoLocaiton(
          {
            longitude: long.toString(),
            latitude: lat.toString(),
          },

          (response: any) => {
            const { city, state } = response
            setCity(city)
            setState(state)
          },

          () => { }
        )
      )
    })
  }, [])
  React.useEffect(() => {
    document.title = 'Reserve your Spot | Matter'


    const inputType: any = document.querySelector("#partner_whatsAppNo")


    inputType?.addEventListener('keydown', function(e:any) {
      if (e.which === 38 || e.which === 40) {
          e.preventDefault();
      }
  });

  }, [])

  const handleSubmit = (values: IntialValues, resetForm: any) => {
    // console.log({ values })
    delete values.teams
    !values.receiveNotifications && delete values.whatsAppNo
    delete values.receiveNotifications
    dispatch(
      registerInterestUser(
        values,
        (data: any) => {
          console.log('Show Confirmation Screen', data)

          window.scrollTo(0, 0)

          setShowForm(false)
          // dispatch({
          //   type: ActionTypes.RESET_USER_DETAILS,
          // })
          // dispatch({
          //   type: ActionTypes.PHONE_NUMBER_RYI,
          //   payload: {
          //     phone: '',
          //     otpVerifed: false,
          //     verifiedPhoneNumber: '',
          //   },
          // })
        },

        (error: any) => {
          console.log(error)
          if (
            error.message ===
            `Reserve interest count for phone '${values.phone}' exceeded!`
          ) {
            setLimitStatus(true)
            resetForm()
            // setConfirmationScreen(true)
            // setShowForm(false)
            window.scrollTo(0, 0)
            // setConfirmationMessage(true)
            // setShowHeaderText(true)
            // dispatch({
            //   type: ActionTypes.RESET_USER_DETAILS,
            // })
          }

          if (error.message === `Given phone ${values.phone} already exists`) {
            seterrorToastMessage(error.message)
            resetForm()
          }
        }
      )
    )

    seterrorToastMessage('')
  }

  return (
    <div className="ryi-module ">
      <h1 className="visuallyHidden">Reserve Your AERA</h1>
      <HeadlessComponent
        title="Reserve your spot Today - Matter Electric Bike | Matter"
         conicalLink="https://www.matter.in/reserve-your-aera" 
        description="Be the first to ride the Matter AERA electric bike in your area. Reserve yours today and join our waitlist."
        />

      {errorToastMessage && (
        <Toaster successMessage="" failMessage={errorToastMessage} />
      )}
      <Header />
      <div className="banner-section">
        <img src={Images.RYI_Banner} alt="" />
      </div>
      <div className="ryi-content">
        <div className="enter-phone-card fullWidth">
          {showForm ? (
            <>
              <h2 className="monument">
                Reserve <br />
                <span className="primary">your spot!</span>
              </h2>
              <p className="small subText mt-20 mb-24">
                Join our exclusive waitlist to be the first to know when Matter AERA becomes available in your area.
              </p>

              <Formik
                validationSchema={reserveYourAera}
                initialValues={{
                  ...intialVal,
                  city,
                  state,
                  bikeModel: createPrebookingAera?.productName,
                  bikeColor: createPrebookingAera?.availableColors?.find(
                    (item) => item.id === createPrebookingAera.selectedColorId
                  )?.colorName,
                }}
                enableReinitialize
                onSubmit={(values, { resetForm }) => {
                  handleSubmit(values, resetForm)
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  touched,
                  values,
                  isValid,
                  dirty,
                  setFieldValue,
                  handleSubmit,
                }) => {
                  const {
                    firstName,
                    lastName,
                    phone,
                    email,
                    receiveNotifications,
                    whatsAppNo,
                    city,
                    state,
                    teams,
                    bikeModel,
                    bikeColor,
                  } = values

                  console.log('Before', values)

                  console.log('Errors', errors)

                  return (
                    <Form>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="mobileNumber"
                            placeholder="Phone Number"
                            name="phone"
                            autoComplete="off"
                            type="number"
                           onWheel={(e) => document?.activeElement?.blur()}

                            disabled
                            value={phone}
                            onChange={(e) => {
                              if (e.target.value.length === 11) return
                              setFieldValue('phone', e.target.value)
                            }}
                            onBlur={handleBlur}
                            error={Boolean(touched.phone && errors.phone)}
                            helperText={touched.phone && errors.phone}
                            onKeyDown={(e: any) => {
                              ; (phonePreventText.includes(e.key) ||
                                e.key === 'ArrowUp' ||
                                e.key === 'ArrowDown') &&
                                e.preventDefault()
                            }}
                            className="w-100"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className="cc-prop"
                                >
                                  +91
                                </InputAdornment>
                              ),
                              endAdornment: true ? (
                                <CheckCircleIcon className="success ml-12" />
                              ) : (
                                ''
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                placeholder="First Name"
                                name="firstName"
                                value={firstName}
                                disabled
                                autoComplete="off"
                                className="w-100"
                                inputProps={{ maxLength: 25 }}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  errors.firstName === ' '
                                    ? false
                                    : Boolean(
                                      touched.firstName && errors.firstName
                                    )
                                }
                                helperText={
                                  errors.firstName === ' '
                                    ? false
                                    : touched.firstName && errors.firstName
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                placeholder="Last Name"
                                name="lastName"
                                value={lastName}
                                autoComplete="off"
                                disabled
                                className="w-100"
                                onChange={handleChange}
                                inputProps={{ maxLength: 25 }}
                                onBlur={handleBlur}
                                error={
                                  errors.lastName === ' '
                                    ? false
                                    : Boolean(
                                      touched.lastName && errors.lastName
                                    )
                                }
                                helperText={
                                  errors.lastName === ' '
                                    ? false
                                    : touched.lastName && errors.lastName
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                placeholder="City"
                                name="city"
                                value={city}
                                className="w-100"
                                autoComplete="off"
                                onChange={handleChange}
                                inputProps={{ maxLength: 40 }}
                                onBlur={handleBlur}
                                error={
                                  errors.city === ' '
                                    ? false
                                    : Boolean(touched.city && errors.city)
                                }
                                helperText={
                                  errors.city === ' '
                                    ? false
                                    : touched.city && errors.city
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                placeholder="State"
                                name="state"
                                value={state}
                                className="w-100"
                                onChange={handleChange}
                                autoComplete="off"
                                inputProps={{ maxLength: 40 }}
                                onBlur={handleBlur}
                                error={
                                  errors.state === ' '
                                    ? false
                                    : Boolean(touched.state && errors.state)
                                }
                                helperText={
                                  errors.state === ' '
                                    ? false
                                    : touched.state && errors.state
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                className="w-100"
                                placeholder="Email ID"
                                name="email"
                                // disabled
                                value={email}
                                onChange={handleChange}
                                autoComplete="off"
                                disabled
                                onKeyDown={(e: any) =>
                                  [' '].includes(e.key) && e.preventDefault()
                                }
                                onBlur={handleBlur}
                                error={
                                  errors.email === ' '
                                    ? false
                                    : Boolean(touched.email && errors.email)
                                }
                                helperText={
                                  errors.email === ' '
                                    ? false
                                    : touched.email && errors.email
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                id="outlined-select-gender"
                                select
                                label={bikeModel === '' ? 'Variant' : ''}
                                className="w-100 mui-drop"
                                value={bikeModel}
                                name="bikeModel"
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      name: 'bikeModel',
                                      value: e.target.value,
                                    },
                                  })

                                  if (
                                    !availableAeras
                                      .find(
                                        (item) =>
                                          item.productName === e.target.value
                                      )
                                      ?.availableColors.find(
                                        (item) => item.colorName === bikeColor
                                      )
                                  ) {
                                    setFieldValue('bikeColor', '')
                                  }
                                }}
                                InputLabelProps={{ shrink: false }}
                                placeholder="Interested In"
                                // SelectProps={{
                                //   MenuProps: {
                                //     className: classes.menu,
                                //   },
                                // }}
                                margin="normal"
                                variant="outlined"
                              >
                                {availableAeras.map((option: any) => (
                                  <MenuItem
                                    className="interest-aera"
                                    key={option.productName}
                                    value={option.productName}
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    {option.productName}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                id="outlined-select-gender"
                                select
                                label={bikeColor === '' ? 'Colour' : ''}
                                className="w-100 mui-drop"
                                value={bikeColor}
                                name="bikeColor"
                                onChange={handleChange}
                                InputLabelProps={{ shrink: false }}
                                placeholder="Interested In"
                                // SelectProps={{
                                //   MenuProps: {
                                //     className: classes.menu,
                                //   },
                                // }}
                                margin="normal"
                                variant="outlined"
                              >
                                {availableAeras
                                  .find(
                                    (item) => item.productName === bikeModel
                                  )
                                  ?.availableColors?.map((option: any) => (
                                    <MenuItem
                                      className="interest-aera"
                                      key={option.colorName}
                                      value={option.colorName}
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      {option.colorName}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <div className="flex al-start">
                                <Checkbox
                                  name="receiveNotifications"
                                  checked={receiveNotifications}
                                  onChange={handleChange}
                                  checkedIcon={
                                    <img src={Icons.CheckboxActiveIcon} />
                                  }
                                  icon={<img src={Icons.CheckboxIcon} />}
                                />
                                <p className="small subText">
                                  Get important updates on WhatsApp
                                </p>
                              </div>
                              {receiveNotifications && (
                                <TextField
                                  placeholder="WhatsApp Number"
                                  type="number"
                                  className="w-100 mb-10 mt-8"
                                  onWheel={(e) => document?.activeElement?.blur()}
                                  id="partner_whatsAppNo"
                                  name="whatsAppNo"
                                  value={whatsAppNo}
                                  onPaste={(e) =>
                                    handlePasteMethod(
                                      e,
                                      setFieldValue,
                                      'whatsAppNo'
                                    )
                                  }
                                  // inputProps={{ maxLength: 10 }}
                                  onChange={(e) => {
                                    if (e.target.value.length === 11) return
                                    setFieldValue('whatsAppNo', e.target.value)
                                  }}
                                  onKeyDown={(e: any) =>
                                    phonePreventText.includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  onBlur={handleBlur}
                                  error={
                                    errors.whatsAppNo === ' '
                                      ? false
                                      : Boolean(
                                        touched.whatsAppNo &&
                                        errors.whatsAppNo
                                      )
                                  }
                                  helperText={
                                    errors.whatsAppNo === ' '
                                      ? false
                                      : touched.whatsAppNo && errors.whatsAppNo
                                  }
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment
                                        position="start"
                                        className="cc-prop"
                                      >
                                        +91
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              <div className="flex al-start js-start subText">
                                <Field
                                  as={Checkbox}
                                  type="checkbox"
                                  name="teams"
                                  color="primary"
                                  checkedIcon={
                                    <img src={Icons.CheckboxActiveIcon} />
                                  }
                                  icon={<img src={Icons.CheckboxIcon} />}
                                />
                                <p className="small">
                                  By clicking Submit, you agree to our{' '}
                                  <Link
                                    href="/terms-and-conditions"
                                    className="underline primary bold"
                                    target='_blank'
                                  >
                                    T&C of Use
                                  </Link>{' '}
                                  &{' '}
                                  <Link
                                    href="/privacy-policy"
                                    className="underline primary bold"
                                    target='_blank'
                                  >
                                    Privacy Policy
                                  </Link>
                                </p>
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <button
                                className="btn btn-gray w-100"
                                onClick={() => history.goBack()}
                                type="button"
                              >
                                Back
                              </button>
                            </Grid>
                            <Grid item xs={6}>
                              <button
                                className="btn btn-primary w-100"
                                type="submit"
                                disabled={!(isValid && dirty)}
                              >
                                Submit
                              </button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Form>
                  )
                }}
              </Formik>

              <Dialog
                open={limitStatus}
                // onClose={handleCloseImage}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="confirmation-dialog"
                fullWidth
                maxWidth="sm"
              >
                <h5 className="monument uppercase mb-20">
                  Oops! you have
                  <br />
                  reached the Limit
                </h5>
                <div className="dialog-body mb-40">
                  <p className="mb-40">
                    Let's stop with two reservations now, shall we? <br /> We've
                    counted you in, and we'll let you know as soon as we expand
                    to your city.
                  </p>
                </div>

                <Grid
                  container
                  spacing={{ xs: 2, lg: 3 }}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Grid item xs={12} sm={6} lg={5}>
                    <button
                      className="btn btn-gray w-100"
                      onClick={() => setLimitStatus(false)}
                    >
                      Got it!
                    </button>
                  </Grid>
                </Grid>
              </Dialog>
            </>
          ) : (
            <div>
              <Grid container spacing={3}>
                <Grid item md={12} lg={6}>
                  <img src={Icons.CheckGreen} className="info-icon" alt="" />
                  <h2 className="monument mb-28">
                    <span className="primary">We've Reserved</span> <br />
                    a Spot for you!
                  </h2>
                  <p className="large subText mb-24">
                    Have no reservations, for we have your reservation! We promise to keep you in the loop and inform you as soon as we are available in your city. Stick around; it's worth it!
                  </p>
                </Grid>
                <Grid item xs={12} lg={9}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <button className="btn btn-primary w-100 mb-40"
                        onClick={() => history.push('/motorbike')}
                      >
                        Continue Exploring
                      </button>
                    </Grid>
                    <Grid item xs={12}>
                      <h5 className="expect-text">What can you expect next</h5>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <div className="expect-next w-100">
                        <img src={Icons.Mini_Logo_Black} />
                        <div>
                          <h6 className="mb-5 semi-bold">
                            Pre-booking information
                          </h6>
                          <p className="subText">
                            Early bird perks like special price preference in
                            delivery etc.
                          </p>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default RYA
