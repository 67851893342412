import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import Grid from '@mui/material/Grid'
import '../blogs.scss'
import { useDispatch, useSelector } from 'react-redux'
import { ReducerModel } from '../../../reducers/reducerModel'
import { useState } from 'react'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import MarkDown from '../../../components/Markdown'
import homeActions from '../../../actions/homeActions'
import moment from 'moment'
import Icons from '../../../utils/icons'
import Snackbar from '@mui/material/Snackbar'
import { BlogsCategory } from '../../../constants/model'
import ProgressiveImage from '../../../components/ProgressiveImage'
import React from 'react'

const FullBlog = () => {
  const { blogsCategories, blogDetails } = useSelector(
      (store: ReducerModel) => store.home
    )  

  const [showMessage, setShowMessage] = useState(false)

  const { id } = useParams()
  const history = useHistory()
  const dispatch: Function = useDispatch()

  const categoryBlogsMemo = React.useMemo(() => {        
    const blogs : any[] = []
    blogsCategories.map((blogCategory: BlogsCategory) => {        
      blogCategory.allblogs.filter((blog:any) => blog.title === blogDetails.title).map(()=> {   
        blogCategory.allblogs.slice(0,4).map((blog)=> {
          blogs.push(blog)
        })
      })
    }) 
    return blogs
  }, [blogsCategories, blogDetails])

  useEffect(()=> {
    //window.scrollTo(0, 0);
    let blogID = 0;
    
    blogsCategories.map((blogCategory: BlogsCategory) => {  
      const blog = blogCategory.allblogs.filter((blog:any) =>(blog.title.toLowerCase().replace(/ /g,"_").replace(/\?/g, '').replace(/\-/g, "_") === id))
      blogID = blog[0].id
    })
    dispatch(homeActions.getFullBlogs(blogID))        
  },[blogsCategories])

  const handleShare = () => {
    const url = location.href
    navigator.clipboard.writeText(url)
    setShowMessage(true)
  } 

  const handleClose = () => {
    setShowMessage(false)
  }

  const openBlog = (title: string) => {
    let blogTitle = title.toLowerCase().replace(/ /g,"_").replace(/\?/g, '').replace(/\-/g, "_")
    window.open('/blogs/' + blogTitle, '_blank')    
  }

  return (
    <div className="blogs-page-wrapper">
      {showMessage && (
        <Snackbar open={true} autoHideDuration={3000} onClose={handleClose} message="Copied link to your clipboard to share" />          
      )}
      <Header />
      <h1 className="visuallyHidden">Full Blog</h1>

      <div className="full-blog">        
        <div>
          <p className="blog-data-title">
            {blogDetails.title}
          </p>
          <div className="blog-date-share-heading">
            <p className="date">
              <span className="mr-8">{moment(blogDetails.publishDate).format('MMM DD, YYYY')}</span>
              <span className="dot"></span>              
              <span className="ml-8">{blogDetails.readTime} min read</span>            
            </p>
            <img src={Icons.ShareIcon} 
              className="share-icon pointer" 
              alt='Share Icon' 
              onClick={handleShare}
            />
          </div>
          
          <hr className='hr'/>           
          
          <ProgressiveImage alt="Matter Full Blog"                
            className="full-blog-main-image" 
            imgSrc={process.env.STRAPI_URL + blogDetails.matterBlogImageWeb} 
            previewSrc={process.env.STRAPI_URL + blogDetails.matterBlogThumbNailImageWeb} /> 
            
          <p className="full-blog-image-desc">{blogDetails.blogImageDescription}</p>
          <p className="full-blog-intro">{blogDetails.blogIntroduction}</p>
        </div>
        
        <div className="blog-stream-data">
          <MarkDown string={blogDetails.blogStream1} />        
          <Grid container spacing={{xs: 1.5, sm: 3}} className="extra-pictures">
            {blogDetails.blogExtraPictures.map((item:any, index)=> (
              <Grid item xs={12} sm={6} md={6} key={index}>
                <div className="monument">                
                  <img
                    style={{borderRadius: '6px', marginBottom: '20px', height: '275px', width: '100%', objectFit: 'cover'}}
                    src={process.env.STRAPI_URL + item}
                    alt="Matter Full Blog"
                  />                
                </div>
              </Grid>
            ))}            
          </Grid>
          <div className="full-blog-image-desc">
            {blogDetails.extraPicturesDescription}
          </div>
          <MarkDown string={blogDetails.blogStream2} />      
          <button
            onClick={() => {history.push('/blogs')}}
            className="btn-back"
          >
            Back to Blogs              
          </button>
          <hr className='hr'/>    
        </div>
        
        <div className="blog-data-content">
          <h5 className="monument large black mb-16">
            More Articles
          </h5>
          <Grid container spacing={{xs: 1.5, sm: 3}}>
            {categoryBlogsMemo.map((item, index) =>             
              <Grid item xs={12} sm={6} md={6} key={index}>
                <div className="monument pointer blog-posts" onClick={() => {openBlog(item.title)}}>              
                  <ProgressiveImage alt="Matter Full Blog"                    
                    className="more-posts-blog-img"
                    imgSrc={process.env.STRAPI_URL + item.matterBlogImageWeb} 
                    previewSrc={process.env.STRAPI_URL + item.matterBlogThumbNailImageWeb} />                  
                  <p className="date mr-8">{moment(item.publishDate).format('MMM DD, YYYY')}</p>
                  <span className="dot"></span>                  
                  <p className="date ml-8">{item.readTime} min read</p>
                  <p className="blog-data-title mt-8">
                    {item.title}   
                  </p>                
                  <p className="blog-introduction">
                    {item.blogIntroduction} 
                  </p>
                </div>
              </Grid>
            )}              
          </Grid>
        </div>
      </div>
      <Footer />
    </div>    
  )
}

export default FullBlog
