import React, { useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Images from '../../utils/images'
import './ryi.scss'
import EnterPhone from './components/EnterPhone'
import FullDetail from './components/FullDetail'
import Icons from '../../utils/icons'
import Grid from '@mui/material/Grid'
import ProgressiveImage from '../../components/ProgressiveImage'
import HeadlessComponent from '../../components/HeadlessComponent'
import { useDispatch, useSelector } from 'react-redux'
import { resetRYI } from '../../actions/registerInterest'
import { ReducerModel } from '../../reducers/reducerModel'

const RYI = () => {
  const [showFullDetail, setShowFullDetail] = useState(false)
  const [showHeaderText, setShowHeaderText] = useState(false)

  const { appConfig } = useSelector((store: ReducerModel) => store.home)

  const dispatch: Function = useDispatch()
  React.useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(resetRYI())
  }, [])

  const verifyOTPToShowFullDetails = () => {
    setShowFullDetail(true)
  }

  return (
    <div className="ryi-module">
      <h1 className="visuallyHidden">Explore the Era of Matter with us.</h1>
      {appConfig.joinWaitListkey ? <HeadlessComponent
          title="Matter AERA Electric Two- Wheelers Bikes Register Your Interest Now"
          conicalLink="https://www.matter.in/register-your-interest" 
          description="Express Interest in Innovative Matter AERA Electric Bike. Join the Future of Sustainable Transportation with Matter AERA. Register Now!"
      /> : <HeadlessComponent
          title="Register Your Interest Today - Matter Electric Bike | Matter"
          conicalLink="https://www.matter.in/register-your-interest" 
          description="Register your interest to get regular updates on all things Matter. Be a part of Matter's exclusive community by signing up."
      />}
      
      <Header />
      <div className="banner-section">
        {/* <img src={Images.RYI_Banner} alt="" /> */}
        <ProgressiveImage
          imgSrc={Images.RYI_Banner}
          previewSrc={Images.RYI_BannerThumbnail}
        />
      </div>
      <div className="ryi-content">
        <div
          className={`enter-phone-card ${showFullDetail ? 'fullWidth' : ''}`}
        >
          {!showHeaderText ? (
            appConfig.joinWaitListkey ? (
              <>
                <h2 className="monument">
                  Join the
                  <span className="primary"> Waitlist</span>
                </h2>
              </>
            ) : (
              <h2 className="monument">
                Register <br />
                <span className="primary">your interest</span>
              </h2>
            )
          ) : null}
          {!showFullDetail ? (
            <EnterPhone
              verifyOTPToShowFullDetails={verifyOTPToShowFullDetails}
              setShowHeaderText={setShowHeaderText}
            />
          ) : (
            <FullDetail
              setShowHeaderText={setShowHeaderText}
              setShowFullDetail={setShowFullDetail}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default RYI
