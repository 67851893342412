import * as React from 'react';
import './homemodal.scss'
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';
import Images from '../../utils/images';
import { numberWithCommas } from '../../utils/common';

export default function HomeModal({ open, handleClose }: any) {
    const history = useHistory()
    return (
        <div>
            <Dialog
                maxWidth={"lg"}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className='modaldialog'
            >
                <DialogContent className='modalcontent' dividers>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        className='close-dialog-icon'
                    >
                        <CloseIcon />
                    </IconButton>
                    <Grid container spacing={2}>
                        <Grid item xs={4} display={{ xs: "none", lg: "block" }}>
                            <img src={Images.homeHeaderPopupWeb} />
                        </Grid>
                        <Grid item xs={12} display={{ xs: "block", lg: "none" }}>
                            <img src={Images.homeHeaderPopupMobile} />
                        </Grid>
                        <Grid item xs={12} lg={8}>
                            <div className='dialog-prebook-content'>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <h4 className="monument mb-20">Pre-Book Now !</h4>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {/* <Grid item xs={12} md={6}>
                                    <p className="monument text">#MissionNoEmission Offer</p>
                                        <h5 className="monument pt-25">₹{numberWithCommas('30000')} <span className='h5_small'>price benefits</span></h5>
                                        <h5 className="monument pt-25">₹{numberWithCommas('20000')} <span className='h5_small'>worth care package</span></h5>
                                        <h4 className="monument pt-25 color_blue">₹{numberWithCommas('50000')} <span className='h5_small'>total benefits</span></h4>
                                    </Grid> */}

                                        <h5 className="monument subText">Pre-Register Price starting at</h5>
                                        <h4 className="monument mt-10">
                                            ₹{numberWithCommas('173999')}
                                        </h4>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <hr className='mt-12 mb-12' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <h5 className="monument">Benefits of Pre-booking</h5>
                                        <ul className="list-wrapper pt-1">
                                            {/* <li className="list">Enjoy a price benefit of ₹{numberWithCommas('30000')} on the remarkable MATTER AERA.</li> */}
                                            <li className="list">Type in the promo code WEMATTER to get ₹1,000/- off </li>
                                            <li className="list">Exclusive Experience Rides starting in June. </li>
                                            <li className="list">Welcome Package only for individuals who Pre-Book. </li>
                                            <li className="list">Exciting financing options available ! </li>
                                            <li className="list">Easy cancellation with full refunds !</li>
                                        </ul>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="flex al-end js-between mt-20 mt-xs-0 flex-wrap">
                                        <a className='link mr-20 mt-20' target='_blank' href='/online-prebooking-terms'>Online Pre-Booking T&C</a>
                                            <a className='linkbtn mt-20' onClick={() => history.push('/prebook')}>Pre-Book Now @ ₹{numberWithCommas('999')} Only !</a>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}